import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { getQueryParam } from './utils/library';
import { Service } from './models/service.model';

ReactDOM.render(
  <React.StrictMode>
    <App
      clientId = {getQueryParam('clientId')}
      service = {getQueryParam('service') as Service}
      teethAmount={Number(getQueryParam('amount'))}
     />
  </React.StrictMode>,
  document.getElementById('root')
);