import React from 'react';
import styles from './calculator.module.scss';
import { addNewline } from '../../utils/library';
import PriceBlock from './price-block/price-block';
import ProsAndCons from './pros-and-cons/pros-and-cons';
import { VeneersCalculatorData } from '../../models/veneers-calculator.model';
import { Sections } from '../../models/calculator-content.model';
import Advantages from '../advantages/advantages';

interface CalculatorVeneersProps {
	data: VeneersCalculatorData
	content: Sections[]
  amount: number
}

function getVeneersText(amount:number, type:'accusative' | 'genitive') {
	switch(amount) {
		case 1: {
			return (type == "accusative") ? `установку ${amount} винира` : `установки ${amount} винира`;
		}
		case 2: case 3: case 4: case 5: case 6: {
			return (type == "accusative") ? `установку ${amount} виниров` : `установки ${amount} виниров`;
		}
		default: {
			return (type == "accusative") ? 'полную реставрацию улыбки' : 'полной реставрации улыбки';
		}
	}
}

export default function CalculatorVeneers({data, content, amount}:CalculatorVeneersProps) {
	const { variants, installment } = data;

	return (
		<section className={styles.results}>
			{content?.map(({role, chapter, articles}) => {
				return <>
					<h2 key={chapter} className={styles.chapter}>
						{addNewline(chapter.replace('%NG', getVeneersText(amount, "genitive")).replace('%NA', getVeneersText(amount, "accusative")))}
					</h2>

					{articles.map(({type, name, header, description, pros, cons}) => {
						const variant = variants.find(variant => variant.name == name);

						if (type == 'variant' && !variant) return <></>;

						return <div className={styles.block}>
							{header && <h3 className={styles.heading}>{header}</h3>}
							{description.map((paragraph:string) => {
                switch (paragraph) {
                  case "advantages": return <Advantages/>;
                  default: return <p>{paragraph}</p>;
                }
              })}

							{(pros || cons) && <ProsAndCons pros={pros} cons={cons}/>}

							{type == 'variant' && variant && <PriceBlock 
								header={`цена на ${getVeneersText(amount, "accusative")}`} 
								price={variant.price}
								multiple={amount} 
								installment={installment}
							>
								{variant.details && <div className={styles.details}>
									<h3>Что входит в стоимость:</h3>
									<div>{variant.details.join(', ')}</div>
								</div>}
							</PriceBlock>}
						</div>
					})}
				</>
			})}
		</section>
	)
}