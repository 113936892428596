import React from 'react';
import { priceFormat } from '../../../utils/library';
import styles from './price-detail.module.scss'

export default function PriceDetail({price, label, multiple}) {
	return (
		<li className={styles.default}>
			{label}:&nbsp;
      <i className={styles.noWrap}>
        <mark>{priceFormat(price)}</mark>
			  {multiple && <i> × <b>{multiple}</b></i>}
      </i>
		</li>
	)
}