import React, { Fragment } from 'react';
import styles from './calculator.module.scss';
import SimpleBlock from './simple-block/simple-block';
import PriceBlock from './price-block/price-block';
import { priceFormat, jawsText, addNewline } from '../../utils/library';
import ProsAndCons from './pros-and-cons/pros-and-cons';
import { Sections } from '../../models/calculator-content.model';
import { BracesCalculatorData } from '../../models/braces-calculator.model';
import Advantages from '../advantages/advantages';

interface CalculatorBracesProps {
	data: BracesCalculatorData
	content: Sections[]
  amount: number
}

export default function CalculatorBraces({data, content, amount}:CalculatorBracesProps) {
	const { preparing, variants, steps, installment, jawsQuantity } = data;
	const jawsString = jawsText(jawsQuantity == 2 ? jawsQuantity : amount);
	
	return (
		<section className={styles.results}>
			{content?.map(({role, chapter, articles}) => {
				if (role == 'steps' && !steps) return <Fragment key={null+chapter}></Fragment>;

				return <Fragment key={role+chapter}>
					<h2 className={styles.chapter}>{addNewline(chapter.replace('%N', jawsString))}</h2>

					{articles.map(({type, name, header, description, image, pros, cons, duration}) => {
						const variant = variants.find(variant => variant.name == name);

						if (type == 'variant' && !variant) return <></>;

						return <div key={type+header} className={styles.block}>
							<h3 className={styles.heading}>{header}</h3>

							{image && <div className={styles.braceImg}>
								<img src={image} alt={header}/>
							</div>}

							{description.map((paragraph:string) => {
								switch (paragraph) {

									case "price": 
										return preparing.map(({label, price}) => (
											<SimpleBlock header={label}>	
												<p>Средняя стоимость: <mark>{priceFormat(price)}</mark></p>
											</SimpleBlock>
										));

                  case "advantages": return <Advantages/>;

									default: return <p key={paragraph.substr(0, 30)}>{paragraph}</p>;
								}
							})}

							{(pros || cons) && <ProsAndCons key={pros} pros={pros} cons={cons}/>}

							{variant && <PriceBlock 
								header={`цена на ${jawsText(jawsQuantity == 2 ? jawsQuantity : amount)}`}
								price={(jawsQuantity == 2 ? variant.price : variant.price*amount)}
								installment={installment}
							><p>Длительность лечения: <mark>{duration}</mark></p>
							</PriceBlock>}

						</div>
					})}
				</Fragment>
			})}	
		</section>
	)
}