import React from 'react';
import styles from './price-block.module.scss';
import { priceFormat } from '../../../utils/library';

interface PriceBlockProps {
	header: string
	price: number
	installment?: number
	multiple?:number
	children?: JSX.Element
}

export default function PriceBlock({header, price, installment, multiple, children}:PriceBlockProps) {
	return (
		<section className={styles.default}>
			<h3>{header}</h3>

			{installment && <p>
				В рассрочку на <b>{installment} месяцев</b>:&nbsp;
				<mark className={styles.noWrap}>
          {priceFormat((multiple ? price*multiple : price)/installment)}/меc
         </mark>
			</p>}

			<p>{installment ? 'Полная сумма:': 'Стоимость:'}&nbsp;
				{multiple ? 
					<>
						<mark>{priceFormat(price)}</mark> × <b>{multiple}</b> = &nbsp;
						<mark className={styles.noWrap}>{priceFormat(price*multiple)}</mark>
					</> 
					: 
					<>
						{price && <mark className={styles.noWrap}>{ priceFormat(price)}</mark>}
					</>
				}
			</p>
			<div className={styles.extra}>{children}</div>
		</section>
	)
}