import React, {Fragment} from 'react';
import PriceBlock from './price-block/price-block';
import PrcieDetail from './price-detail/price-detail';
import styles from './calculator.module.scss';
import { getTeethText, addNewline, defineInstallment } from '../../utils/library';
import { Sections } from '../../models/calculator-content.model';
import { ImplantationCalculatorData, Detail } from '../../models/implantation-calculator.model';
import Advantages from '../advantages/advantages';

interface CalculatorImplantationProps {
	data: ImplantationCalculatorData
	content: Sections[]
  amount: number
}

export default function CalculatorImplantation({ data, content, amount }:CalculatorImplantationProps) {
	const jaws = amount > 14 ? 2 : 1;
	const { momentary, installment } = data;

	return (
		<section className={styles.results}>
			{content?.map(({role, chapter, articles}) => {
				if (role == 'all4' && ( amount < 10 || !data.all4)) return <></>;
				if (role == 'all6' && ( amount < 10 || !data.all6)) return <></>;
				if (role == 'dentures' && ( amount < 10 || !data.dentures)) return <></>;

				const fullPriceFactor = (role == 'all4' || role == 'all6' || role == 'dentures') ? jaws : amount;

				return <Fragment key={chapter + role}>
					<h2 className={styles.chapter}>{addNewline(chapter.replace('%N', getTeethText(amount)))}</h2>

					{articles.map(({type, header, description}) => {
						if (type == 'momentary' && !momentary) return <></>;

						return <div key={header} className={styles.block}>
							{header && <h3 className={styles.heading}>{header}</h3>}

							{description.map((paragraph:string) => {
								switch (paragraph) {

									case "price": 
										return role && data[role as ('all6' | 'all4' | 'dentures')].map(({grade, price, details, material}) => (
											<PriceBlock
												key={`${role}-${grade}`}
												header={grade}
												price={price * fullPriceFactor}
												installment={installment}
											><>
												{details && <div className={styles.details}>
													<h3>Что входит в стоимость:</h3>
													<ul>{details.map(detail => (
														<PrcieDetail key={detail.label} {...detail} multiple={detailPriceFactor(role, detail, fullPriceFactor)} />
													))}</ul>
												</div>}
												{material ? <p>Материал: {material}</p> : undefined}
											</></PriceBlock>
										));

                  case "advantages": return <Advantages key="advantages"/>;

									default: return <p key={paragraph}>{defineInstallment(paragraph, installment)}</p>;
								}
							})}
						</div>
					})}
				</Fragment>
			})}
		</section>
	);
}

function detailPriceFactor(role:string, detail:Detail, fullPriceFactor:number):number {
	switch (role) {
		case "all4": return fullPriceFactor * (detail?.name == 'prosthesis' ? 1 : 4);
		case "all6": return fullPriceFactor * (detail?.name == 'prosthesis' ? 1 : 6);
		default: return fullPriceFactor;
	}
}