import React from 'react';
import { useCalculator } from '../../common-effects';
import styles from './calculator.module.scss';
import { getTeethText, addNewline, defineInstallment, setBold } from '../../utils/library';
import PriceBlock from './price-block/price-block';
import PrcieDetail from './price-detail/price-detail';
import { Sections } from '../../models/calculator-content.model';
import { RemoveCalculatorData } from '../../models/remove-calculator.model';
import { ImplantationCalculatorData, ImplantationCase } from '../../models/implantation-calculator.model';
import Advantages from '../advantages/advantages';

type ClassicAndDentures = {[key in 'classic' | 'dentures']: ImplantationCase[] | undefined };

interface CalculatorRemoveProps {
	data: RemoveCalculatorData
	content: Sections[]
  amount: number
  clientId: string
}

export default function CalculatorRemove({data, content, amount, clientId}:CalculatorRemoveProps) {
	var { classic, dentures, installment } = useCalculator<ImplantationCalculatorData>(clientId, 'implantation') || {};
	const implantation:ClassicAndDentures = { classic, dentures };
	const { variants } = data;

	return (
		<section className={styles.results}>
			{content?.map(({chapter, articles}) => {
				return <>
					<h2 key={chapter} className={styles.chapter}>{addNewline(chapter.replace('%N', getTeethText(amount)))}</h2>

					{articles.map(({type, header, description}) => {

						const fullPriceFactor = (type == 'dentures') ? 1 : amount;

						return <div key={`article-${header}`} className={styles.block}>
							{header && <h3 key={header} className={styles.heading}>{header.replace('%N', getTeethText(amount))}</h3>}
							{description.map((paragraph:string, index:number) => {
								switch (paragraph) {
									case "variants": {
										return variants.map(({name, price}) => (
											<PriceBlock
												key={name}
												header={name}
												price={price}
												multiple={amount}
											/>
										))
									}
									case "implantation": {
										return implantation[type as 'classic' | 'dentures']?.map(({grade, price, details, material}) => (
											<PriceBlock
												key={`${type}-${grade}`}
												header={grade}
												price={price * fullPriceFactor}
												installment={installment}
											><>
												{details && <div key={`details-${type}`} className={styles.details}>
													<h3>Что входит в стоимость:</h3>
													<ul>{details.map(detail => (
														<li key={detail.name}><PrcieDetail {...detail} multiple={amount} /></li>
													))}</ul>
												</div>}
												{material ? <p key={material}>Материал: {material}</p> : undefined}
											</></PriceBlock>
										))
									}

                  case "advantages": return <Advantages/>;
                  
									default: return <p key={`${type}-${index}`}>{setBold(defineInstallment(paragraph, installment))}</p>;
								}
							})}
						</div>
					})}
				</>
			})}
		</section>
	)
}