import React from 'react';
import { useCalculator } from '../../common-effects';
import styles from './calculator.module.scss';
import { getTeethText, addNewline, defineInstallment } from '../../utils/library';
import PriceBlock from './price-block/price-block';
import PrcieDetail from './price-detail/price-detail';
import { Sections } from '../../models/calculator-content.model';
import Advantages from '../advantages/advantages';

import { ImplantationCalculatorData, ImplantationCase } from '../../models/implantation-calculator.model';
import { ProstheticsCalculatorData, ProstheticsCase } from '../../models/prosthetics-calculator.model';

type Offers = {[key in 'all4or6' | 'classic' | 'dentures' | 'prosthetics']:(ImplantationCase & ProstheticsCase)[] | undefined};

interface CalculatorProstheticsProps {
	data: ProstheticsCalculatorData
	content: Sections[]
  amount: number
  clientId: string
}

export default function CalculatorProsthetics({data, content, amount, clientId}:CalculatorProstheticsProps) {
	const jaws = amount > 14 ? 2 : 1;
	const { all4, all6, classic, dentures } = useCalculator<ImplantationCalculatorData>(clientId, 'implantation') || {};
	const { installment, prosthetics } = data;

	var all4or6:ImplantationCase[] | undefined = undefined;
	if (all4) all4or6 = all4.map(({price, grade}) => ({price, grade}));
		else if (all6) all4or6 = all6.map(({price, grade}) => ({price, grade}));

	const offers:Offers = { all4or6, classic, dentures, prosthetics };

	return (
		<section className={styles.results}>
			{content?.map(({chapter, articles}) => {

				return <>
					<h2 className={styles.chapter}>{addNewline(chapter.replace('%N', getTeethText(amount)))}</h2>

					{articles.map(({type, condition, header, description}) => {
						if (type == 'dentures' && (!dentures || amount < 10)) return <></>;
						if (type == 'all4or6' && (!all4or6 || amount < 10)) return <></>;
						if (type == 'classic' && condition == 'amount<10' && (!classic || amount >= 10)) return <></>;
						if (type == 'classic' && condition == 'amount>10' && (!classic || amount < 10)) return <></>;

						const fullPriceFactor = (type == 'all4or6' || type == 'dentures') ? jaws : amount;

						return <div className={styles.block}>
							{header && <h3 className={styles.heading}>{header}</h3>}

							{description.map((paragraph:string) => {
								switch (paragraph) {

									case "price": 
										return offers[type as keyof Offers]?.map(({grade, price, details, material}) => (
											<PriceBlock
												key={`${type}-${grade}`}
												header={grade}
												price={price * fullPriceFactor}
												installment={installment}
											><>
												{details && <div className={styles.details}>
													<h3>Что входит в стоимость:</h3>
													<ul>{details.map(detail => (
														<li><PrcieDetail {...detail} multiple={amount} /></li>
													))}</ul>
												</div>}
												{material ? <p>Материал: {material}</p> : undefined}
											</></PriceBlock>
										));

                  case "advantages": return <Advantages/>;

									default: return <p>{defineInstallment(paragraph, installment)}</p>;
								}
							})}
						</div>
					})}
				</>
			})}
		</section>
	);
}