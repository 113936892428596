import React from 'react';
import styles from './calculator.module.scss';
import { addNewline, defineInstallment } from '../../utils/library';
import PriceBlock from './price-block/price-block';
import { Sections } from '../../models/calculator-content.model';
import { PediatricsCalculatorData } from '../../models/pediatrics-calculator.model';
import Advantages from '../advantages/advantages';

interface CalculatorPediatricsProps {
	data: PediatricsCalculatorData
	content: Sections[]
}


export default function CalculatorPediatrics({data, content}:CalculatorPediatricsProps) {
	const { caries, pulpitis, remove, plates, braces, plastic, installment } = data;

	return (
		<section className={styles.results}>
			{content?.map(({role, chapter, articles}) => {
				if (role == "caries/pulpitis" && !caries && !pulpitis) return <></>;
				if (role == "remove" && !remove) return <></>;
				if (role == "plastic" && !plastic) return <></>;
				if (role == "plates/braces" && !plates && !braces) return <></>;

				return <>
					<h2 className={styles.chapter}>{addNewline(chapter)}</h2>

					{articles.map(({type, condition, header, description}) => {

						if (type == "caries" && !caries) return <></>;
						if (type == "pulpitis" && !pulpitis) return <></>;
						if (type == "braces" && !braces) return <></>;
						if (type == "plates" && !plates) return <></>;

						return <div key={`block-${header}`} className={styles.block}>
							<h3 key={`header-${header}`} className={styles.heading}>{header}</h3>
								{description.map((paragraph:string) => {
									switch (paragraph) {
										case "price": {
											return {caries, pulpitis, braces, plates, remove, plastic}[type as 'caries' | 'pulpitis' | 'braces' | 'plates' | 'remove' | 'plastic']
												?.map(({label, price}) => (
													<PriceBlock header={label} price={price}/>
												));
										}

                    case "advantages": return <Advantages/>;

										default: return <p key={paragraph}>{defineInstallment(paragraph, installment)}</p>;
									}
								})}
						</div>
					})}
				</>
			})}
		</section>
	)
}