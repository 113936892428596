  import { useState, useEffect } from 'react';
import { Service } from './models/service.model';
import { Sections } from './models/calculator-content.model';
import http from './utils/http';

function addHeaders(clientId:string) {

  return { headers: {
    'x-domain': 'localhost', 
    'x-access-key': 'MYg5mLNqhdrJ0dOIq9AcgSYG0AJ7jq',
    'x-client-id': clientId
  }};
}

export const useCalculatorContent = (clientId:string, service: Service):Sections[] => {
  const [ content, setContent ]:[Sections[], Function] = useState([]);

  useEffect(() => {
    // dispatch(togglePreloader(true));

    http.get(`content/calculator/${service}`, addHeaders(clientId)).then(({data}) => {
      if (data) setContent(data);
      // dispatch(togglePreloader(false));
    });
  }, [clientId, setContent, service]);

  return content;
}

export const useCalculator = <T>(clientId:string, service:Service):T | null => {
  const [data, setData]:[null | T, Function] = useState(null as null | T);

  useEffect(() => {
    http.get(`clients/${clientId}?fields[]=${service}`, addHeaders(clientId)).then(response => {
      if (response?.data[service]) setData(response.data[service].calculator);
    });
  }, [clientId, service]);

  return data;
}

