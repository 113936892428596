import React from 'react';
import styles from './advantages.module.scss';

export default function Advantages() {
	let id = 1;
	return (
		<ul className={styles.advantages}>
			<li><b>{id++}.</b> Врачи. Тысячи пациентов выбрали именно наших врачей</li>
			<li><b>{id++}.</b> Соответствие действующим клиническим стандартам 
			и рекомендациям. Мы несем полную ответственность за результаты 
			лечения. Обязательства клиники зафиксированы в документах 
			(официальный договор)</li>
			<li><b>{id++}.</b> Официальная лицензия выдана Министерством здравоохранения</li>
			<li><b>{id++}.</b> Тщательная стерилизация инструментов</li>
			<li><b>{id++}.</b> Мы выдаем все официальные документы, договора. 
			У нас официальная бухгалтерия. Вы сможете получить налоговый вычет. 
			Это дополнительная выгода для вас 13%</li>
		</ul>
	);
}