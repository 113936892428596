import React from 'react';

export function getQueryParam(param:string) {
  var name = param.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(window.location.search);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export function getTeethText(teeth:number):string {
  var text;
  switch(Number(teeth)) {
    case 1: text = teeth + " зуба"; break;
    case 2: case 3: case 4: case 5:   case 6: case 7: case 8: 
    case 9: text = teeth + " зубов"; break;
    case 10: text = teeth + " и более зубов"; break;
    case 14: text = "зубов 1 челюсти"; break;
    case 28: text = "зубов 2 челюстей"; break;
    default: text = 'none';
  }
  return text;
}

export function jawsText(jaws:number):string {
  switch(Number(jaws)) {
    case 1: return '1 челюсть';
    case 2: return '2 челюсти';
    default: return '';
  }
}

export function addNewline(input:string):JSX.Element[] {
  return input.split('\\n').map(str => {
    return <i key={str}>{str}</i>;
  });
}

export function priceFormat(number:number):string {
  return parseFloat(String(number)).toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, '$1 ') + ' ₽';
}

export function defineInstallment(p:string, installment?:number):string {
  return p.replace(/%I\{(.*?)\}/g, installment ? "$1" : "");
}

export function setBold(p:string):JSX.Element[] | string {
  const boldText = p.match(/%b\{(.*?)\}/g);
  if (!boldText) return p;

  const result = p.split(' ').map(str => {
    if (str.match(/%b\{(.*?)\}/)) return <b key={str}>{str.replace(/%b\{(.*?)\}/, '$1')} </b>;
      else return <>{str} </>;
  })

  return result;
}