import React from 'react';
import { Fade } from '../../animation/animation';
import styles from './preloader.module.scss';

interface PreloaderProps {
	active: boolean
	mode?: 'default' | 'transparent'
}

export default function Preloader({active, mode='default'}:PreloaderProps) {
	return (<Fade duration={500} when={active}>
		<div className={styles[mode]}>
			<div className={styles.indicator}>
				<svg className={styles.svg}>
					{['48%', '40%', '32%'].map((r, i) => (
						<circle 
							key={`diagram-${i}`} 
							className={styles.diagram} 
							r={r} cx='50%' cy='50%'
						/>
					))}
				</svg>
			</div> 
		</div>
	</Fade>);
}