import React from 'react';
import styles from './simple-block.module.scss';

export default function SimpleBlock({header, children}) {
	return (
		<section className={styles.default}>
			{header && <h3>{header}</h3>}
			{children}
		</section>
	)
}