import React from 'react';
import { useCalculatorContent, useCalculator } from './common-effects';
import Implantation from './components/calculator/calculator-implantation';
import Prosthetics from './components/calculator/calculator-prosthetics';
import Braces from './components/calculator/calculator-braces';
import Remove from './components/calculator/calculator-remove';
import Veneers from './components/calculator/calculator-veneers';
import Whitening from './components/calculator/calculator-whitening';
import Treatment from './components/calculator/calculator-treatment';
import Pediatrics from './components/calculator/calculator-pediatrics';
import { ImplantationCalculatorData } from './models/implantation-calculator.model';
import { ProstheticsCalculatorData } from './models/prosthetics-calculator.model';
import { BracesCalculatorData } from './models/braces-calculator.model';
import { TreatmentCalculatorData } from './models/treatment-calculator.model';
import { RemoveCalculatorData } from './models/remove-calculator.model';
import { PediatricsCalculatorData } from './models/pediatrics-calculator.model';
import { VeneersCalculatorData } from './models/veneers-calculator.model';
import { WhiteningCalculatorData } from './models/whitening-calculator.model';
import { Sections } from './models/calculator-content.model';
import Preloader from './components/preloader/preloader';
import { Service } from './models/service.model';

interface AppProps {
  service: Service
  clientId: string
  teethAmount: number
}

type CalculatorData =  ImplantationCalculatorData |  
  ProstheticsCalculatorData | 
  TreatmentCalculatorData | 
  VeneersCalculatorData | 
  WhiteningCalculatorData | 
  RemoveCalculatorData | 
  BracesCalculatorData | 
  PediatricsCalculatorData;

function App({service, clientId, teethAmount}:AppProps) {
  const content:Sections[] = useCalculatorContent(clientId, service);
  const calculator:CalculatorData | null = useCalculator(clientId, service);

  if (!calculator) return <Preloader active={true}/>

  switch (service) {
    case 'implantation': return <Implantation amount={teethAmount} data={calculator as ImplantationCalculatorData} content={content}/>;
    case 'prosthetics': return <Prosthetics clientId={clientId} amount={teethAmount} data={calculator as ProstheticsCalculatorData} content={content}/>;
    case 'braces': return <Braces amount={teethAmount} data={calculator as BracesCalculatorData} content={content}/>;
    case 'remove': return <Remove clientId={clientId} amount={teethAmount} data={calculator as RemoveCalculatorData} content={content}/>;
    case 'veneers': return <Veneers amount={teethAmount} data={calculator as VeneersCalculatorData} content={content}/>;
    case 'whitening': return <Whitening clientId={clientId} data={calculator as WhiteningCalculatorData} content={content}/>;
    case 'treatment': return <Treatment amount={teethAmount} clientId={clientId} data={calculator as TreatmentCalculatorData} content={content}/>;
    case 'pediatrics': return <Pediatrics data={calculator as PediatricsCalculatorData} content={content}/>;
    case 'crowns': return <Prosthetics clientId={clientId} amount={teethAmount} data={calculator as ProstheticsCalculatorData} content={content}/>;
    default: return <div></div>;
  }
}

export default App;
