import React, { Children, cloneElement, useState, useEffect } from 'react';
import './animation.scss';

interface Direction {
  left?: boolean;
  right?: boolean;
  up?: boolean;
  down?: boolean;
}

interface AnimationProps extends Direction {
  name: string;
  children: React.ReactNode;
  opposite?: boolean;
  when?: boolean;
  duration?: number;
  delay?: number;
  effect?:any;
};

export const Slide = (props:Omit<AnimationProps, 'name'>) => <Animation name="slide" {...props}/>
export const Fade  = (props:Omit<AnimationProps, 'name'>) => <Animation name="fade"  {...props}/>
export const Flip  = (props:Omit<AnimationProps, 'name'>) => <Animation name="flip"  {...props}/>
export const Zoom  = (props:Omit<AnimationProps, 'name'>) => <Animation name="zoom"  {...props}/>
export const Animate = (props:AnimationProps) => <CustomAnimation {...props}/>

function Animation({name, children, left, right, up, down, opposite = false, when = true, duration = 600, delay= 0}:AnimationProps) {
  const [mount, setMount] = useState(when);
  const state = when ? 'in' : 'out';
  const side = defineAnimationSide({left, right, up, down}, when, opposite);
  const child = getChild(children);

  const className = `${(child as JSX.Element)?.props.className || ''} ${name}-${state}-${side}`;

  const style = {
    animationDuration: `${duration}ms`,
    animationDelay: `${delay}ms`,
    animationFillMode: 'both'
  }

  const el = cloneElement((child as JSX.Element), { className, style });

  useEffect(() => {
    var timer:NodeJS.Timeout;
    if (!when && mount) timer = setTimeout(() => setMount(when), Number(duration + delay + 100));
      else setMount(when);

    return () => clearTimeout(timer);
  }, [mount, when, duration, delay]);

  return mount ? el : null;
}

function CustomAnimation({ children, when = true, duration = 600, delay = 0, effect}:AnimationProps) {
  const [active, setActive] = useState(false);
  const [content, setContent]:[JSX.Element | null, Function] = useState(null);
  const child = getChild(children);

  const el = cloneElement((child as JSX.Element), (child as JSX.Element)?.props, [content]);
  const activeEl = cloneElement(el, {
    className: `${(child as JSX.Element).props.className || ''} ${effect}` ,
    style: { transition: `all ${duration}ms ease` }
  });

  useEffect(() => {
    setTimeout(() => setActive(when), delay);
    if (when) setContent(<span key={effect}>{(child as JSX.Element).props.children}</span>)
  }, [when, child, delay, effect]);

  return active ? activeEl : el;
}


function defineAnimationSide({left, right, up, down}:Direction, active:boolean, opposite:boolean) {
  if (left) return opposite && !active ? 'right' : 'left';
  if (right) return opposite && !active ? 'left' : 'right';
  if (up) return opposite && !active ? 'down' : 'up';
  if (down) return opposite && !active ? 'up': 'down';
  return 'static';
}

function getChild(children:React.ReactNode):React.ReactNode {
  if (children instanceof Array) return <div>{children}</div>;
    else if (children instanceof Object) {
      const child = Children.only(children);
      return (typeof (child as JSX.Element).type == 'string') ? child : <div>{child}</div>;
    }
}