import React from 'react';
import { useCalculator } from '../../common-effects';
import styles from './calculator.module.scss';
import PriceBlock from './price-block/price-block';
import PriceDetail from './price-detail/price-detail';
import { getTeethText, addNewline, defineInstallment } from '../../utils/library';
import { TreatmentCalculatorData } from '../../models/treatment-calculator.model';
import { ProstheticsCalculatorData } from '../../models/prosthetics-calculator.model';
import { Sections } from '../../models/calculator-content.model';
import Advantages from '../advantages/advantages';

interface CalculatorTreatmentProps {
	data: TreatmentCalculatorData
	content: Sections[]
  amount: number
  clientId: string
}

export default function CalculatorTreatment({data, content, amount, clientId}:CalculatorTreatmentProps) {
	const { caries, pulpitis, periodontitis, filling, installment } = data;
	const { prosthetics, ceramicTab } = useCalculator<ProstheticsCalculatorData>(clientId, 'prosthetics') || {};

	return (
		<section className={styles.results}>
			{content?.map(({role, chapter, articles}) => {
				if (role == 'prosthetics' && !prosthetics && !ceramicTab) return <></>;
        if (role == 'filling' && !filling) return <></>;

				return <>
					<h2 key={chapter} className={styles.chapter}>{addNewline(chapter.replace('%N', getTeethText(amount)))}</h2>

					{articles?.map(({type, name, header, description}) => {
						if (type == "caries" && (!caries || !caries.find(item => item.name == name))) return <></>;
						if (type == "pulpitis" && !pulpitis) return <></>;
						if (type == "periodontitis" && !periodontitis) return <></>;
						if (type == "ceramicTab" && !ceramicTab) return <></>;
						if (type == "prosthetics" && !prosthetics) return <></>;

						const cariesCase = caries.find(cariesCase => cariesCase.name == name);
						const fillingCase = filling?.find(fillingCase => fillingCase.name == name);

						return <div className={styles.block}>
							{header && <h3 className={styles.heading}>{header}</h3>}
							{description?.map((paragraph:string) => {
								switch (paragraph) {
									case "price": {
										if (type == "prosthetics") {
											return prosthetics?.map(({grade, price, details}) => (
												<PriceBlock
													header={`${grade} вариант`}
													price={price}
													multiple={amount}
												>
													{details && <div className={styles.details}>
														<h3>Что входит в стоимость:</h3>
														<ul>{details.map(({label, price}) => (
															<PriceDetail price={price} label={label} multiple={amount}/>
														))}</ul>
													</div>}
												</PriceBlock>
											))
										}

										if (type == "ceramicTab") {
											return <PriceBlock
												header={`Восстановление ${getTeethText(amount)}`}
												price={Number(ceramicTab)}
												multiple={amount}
											/>
										}
										break;
									}

                  case "advantages": return <Advantages/>;
                  
									default: return <p>{defineInstallment(paragraph)}</p>;
								}
							})}

							{(type == "caries") && cariesCase && <PriceBlock 
								header={`цена на лечение ${getTeethText(amount)}`} 
								price={cariesCase.price}
								installment={installment}
								multiple={amount}
							>
								{cariesCase.details && <div className={styles.details}>
									<h3>Что входит в стоимость:</h3>
									<div>{cariesCase.details.join(', ')}</div>
								</div>}
							</PriceBlock>}

							{(type == "filling") && fillingCase && <PriceBlock 
								header={`цена на лечение ${getTeethText(amount)}`} 
								price={fillingCase.price}
								multiple={amount}
							>
								{fillingCase.details && <>
									<h3>Что входит в стоимость:</h3>
									<div>{fillingCase.details.join(', ')}</div>
								</>}
							</PriceBlock>}

							{(type == "pulpitis" || type == "periodontitis") && { pulpitis, periodontitis}[type as 'pulpitis' | 'periodontitis']?.map(({name, price, details}) => (
                <PriceBlock 
                header={`Цена на ${name} × ${amount}`} 
                price={price}
                installment={installment}
                multiple={amount}
              >
								{details && <div className={styles.details}>
									<h3>Что входит в стоимость:</h3>
									<div>{details.join(', ')}</div>
								</div>}
							</PriceBlock>))}

						</div>
					})}
				</>
			})}
		</section>
	)
}