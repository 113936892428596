import axios from 'axios';
import { SERVER_HOST } from '../constants';

const http = axios.create({
  headers: { 
    'Content-Type': 'application/json;charset=UTF-8'
  },
  baseURL: SERVER_HOST
});

export default http;