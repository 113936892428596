import React from 'react';
import styles from './pros-and-cons.module.scss';

interface ProsAndConsProps {
	pros: string[]
	cons: string[]
}

export default function ProsAndCons({pros, cons}:ProsAndConsProps) {
	return <>
		{pros && <section className={styles['pros']}>
			<h3>Плюсы</h3>
			<ul className={styles.list}>{pros.map(point => <li key={point}>{point}</li>)}</ul>
		</section>}
		{cons && <section className={styles['cons']}>
			<h3>Минусы</h3>
			<ul className={styles.list}>{cons.map(point => <li key={point}>{point}</li>)}</ul>
		</section>}
	</>
}