import React from 'react';
import { useCalculator } from '../../common-effects';
import styles from './calculator.module.scss';
import { priceFormat, addNewline } from '../../utils/library';
import SimpleBlock from './simple-block/simple-block';
import PriceBlock from './price-block/price-block';
import ProsAndCons from './pros-and-cons/pros-and-cons';
import { WhiteningCalculatorData }  from '../../models/whitening-calculator.model';
import { Sections } from '../../models/calculator-content.model';
import { VeneersCalculatorData } from '../../models/veneers-calculator.model';
import Advantages from '../advantages/advantages';

interface CalculatorWhiteningProps {
	data: WhiteningCalculatorData
	content: Sections[]
  clientId: string
}

export default function CalculatorWhitening({data, content, clientId}:CalculatorWhiteningProps) {
	const { variants, remineralization } = data;
	const { variants: veneersVariants, installment } = useCalculator<VeneersCalculatorData>(clientId, 'veneers') || {};

	return (
		<section className={styles.results}>
			{content?.map(({role, chapter, articles}) => {
				if (role == "veneersVariants" && !veneersVariants) return;
				if (role == "remineralization" && !remineralization) return;

				return <>
					<h2 key={chapter} className={styles.chapter}>{addNewline(chapter)}</h2>

					{articles.map(({type, name, header, description, pros, cons}) => {
            if (type == "variant" && !variants.find(variant => variant.name == name)) return <></>;
            if (role == "veneersVariants" && !veneersVariants?.find(variant => variant.name == name)) return <></>;

						return <div className={styles.block}>
							{header && <h3 className={styles.heading}>{header}</h3>}

							{(pros || cons) && <ProsAndCons pros={pros} cons={cons}/>}

							{description.map((paragraph:string) => {
								switch (paragraph) {

									case "price": 
										if (role == 'remineralization') {
											return remineralization?.map(({label, price}) => (
												<SimpleBlock header={label}>	
													<p>Средняя стоимость: <mark>{priceFormat(price)}</mark></p>
												</SimpleBlock>
											));
										}

										if (role == 'whiteningVariants') {
											return variants?.find(variant => variant.name == name)?.offers?.map(offer => (
												<PriceBlock header={offer.label} price={offer.price}>
													{offer.details && <>
														<h3>Что входит в стоимость:</h3>
														<div className={styles.details}>{offer.details.join(', ')}</div>
													</>}
												</PriceBlock>
											))
										}

										if (role == 'veneersVariants') {
											const variant = veneersVariants?.find(variant => variant.name == name);
											if (variant) return <PriceBlock 
												header={`цена на установку 1 пластины`} 
												price={variant.price}
												installment={installment}
											>
												{variant.details && <>
													<h3>Что входит в стоимость:</h3>
													<div>{variant.details.join(', ')}</div>
												</>}
											</PriceBlock>
										}

										return <></>;

									case "advantages": return <Advantages/>;
                  
                  default: return <p>{paragraph}</p>;
								}
							})}
						</div>
					})}
				</>
			})}
		</section>
	)
}